<template>
	<div class="molecule-tab-button">
		<ul>
			<li
				v-for="(item, index) in renderItem"
				:key="`tab-button-${item.title}-${index}`"
				:class="{ active: item.active }"
				:data-testid="`performance-tab-${item.id}`"
				@click="handleClick(item.id)"
			>
				<div class="main-text">
					<bg-text size="title-5">
						{{ item.title }}
					</bg-text>
				</div>
				<div class="right-side">
					<bg-text size="body-2" v-if="!withoutNumber">
						{{ item.total }}
					</bg-text>
					<bg-icon name="chevron-right" size="sm" />
				</div>
				<bg-divider v-if="!noDivider" />
			</li>
		</ul>
	</div>
</template>

<script>
import {
	BgText,
	BgIcon,
	BgDivider
} from 'bangul-vue';

export default {
	name: 'TabButton',

	components: {
		BgText,
		BgIcon,
		BgDivider
	},

	props: {
		data: {
			type: Array,
			required: true
			/*
			Object example:
			{
				id: 'tab01', <- as a payload on click event
				title: 'Kunjungan Iklan 1', <- displayed on left side
				total: 2401, <- displayed on right side
				active: false <- toggle active class
			},
			*/
		},
		noDivider: {
			type: Boolean,
			default: false
		},
		withoutNumber: {
			type: Boolean,
			default: false
		},
		filtered: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		renderItem() {
			return this.filtered ? this.reducedItem : this.data;
		},
		reducedItem() {
			return this.data.reduce((prev, cur) => {
			 if (cur.total !== null) {
			 	prev.push(cur);
			 }
				return prev
			}, []);
		}
	},

	methods: {
		handleClick(payload) {
			this.$emit('handle-click', payload);
		}
	}
}
</script>

<style lang="scss" scoped src="./TabButton.scss" />